import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import Openrouteservice from 'openrouteservice-js'

const searouteOptions = {
    method: 'GET',
    headers: {
        accept: 'application/json',
        'x-api-key': 'qBOJSLOVSQ9fkRRWBMfzTuzBiGeF5b6q6PQmmC10'
    }
};

const orsClient = new Openrouteservice.Directions({
    api_key: '5b3ce3597851110001cf6248d1ad95c7b0fc4ec08175da04bd49b34a'
});

require('mapbox-gl/dist/mapbox-gl.css');
require('../../scss/frontend/network.scss');
require('../../scss/commons/network_globe.scss');

$(document).ready(function () {
    mapboxgl.accessToken = 'pk.eyJ1Ijoib2ltbWVpIiwiYSI6ImNsd2l6N2RydjBwdTIya293aTkyMmgxNmsifQ.n9BMEe1VINjZWpDBthzWSA';

    let map_center = centerOffice.latLng.coordinates;
    let map_zoom = 2;
    let map_scroll_zoom = true;

    if ($('#network-map').data('map-center')) {
        map_center = $('#network-map').data('map-center');
    }
    if ($('#network-map').data('map-zoom')) {
        map_zoom = $('#network-map').data('map-zoom');
    }
    if ($('#network-map').data('map-scroll-zoom') !== undefined) {
        map_scroll_zoom = $('#network-map').data('map-scroll-zoom');
    }

    const map = new mapboxgl.Map({
        container: 'network-map',
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        // style: 'mapbox://styles/mapbox/outdoors-v12',
        zoom: map_zoom,
        center: map_center,
        projection: 'globe',
        scrollZoom: map_scroll_zoom,
    });

    for (const routeA of routes) {
        for (const routeR of routes) {
            if (routeA.originName == routeR.destinationName && routeR.originName == routeA.destinationName) {
                routeA.returnRoute = routeR.id;
            }
        }
    }

    map.on('load', () => {

        var scrollTo = null;
        var scrollTime = 0;

        // Slow down zoom of mouse wheel
        map.scrollZoom.setWheelZoomRate(5);

// Set the default atmosphere style
        map.setFog({
            'range': [-1, 10],
            'horizon-blend': 0.05,
            'color': '#53679f',
            'high-color': '#063d7a',
            'space-color': '#022f5e',
            'star-intensity': 0.25
        });

        map.addSource('mapbox-dem', {
            'type': 'raster-dem',
            'url': 'mapbox://mapbox.terrain-rgb'
        });

        map.setTerrain({
            'source': 'mapbox-dem',
            'exaggeration': 1.5
        });

        // Load an image from path.
        const icons = {
            airplane: '/build/map/airplane.png',
            truck: '/build/map/truck.png',
            ship: '/build/map/ship.png',
        };

        map.loadImage(
            icons['ship'],
            (error, image) => {
                if (error) {
                    console.error('ship', error);
                    throw error;
                }

                // Add the image to the map style.
                map.addImage('ship', image);
            }
        );

        map.loadImage(
            icons['airplane'],
            (error, image) => {
                if (error) {
                    console.error('airplane', error);
                    throw error;
                }

                // Add the image to the map style.
                map.addImage('airplane', image);
            }
        );

        map.loadImage(
            icons['truck'],
            (error, image) => {
                if (error) {
                    console.error('truck', error);
                    throw error;
                }

                // Add the image to the map style.
                map.addImage('truck', image);
            }
        );

        let n = 0;
        const intervalId = setInterval(function () {
            startAnimation(n == 1 || n == 4 || n == 7 || n == 9 ? 'airplane' : 'ship');
            n++;
            if (n == 10) {
                clearInterval(intervalId);
            }
        }, 25);

        let countries = {};
        const geojsonOffice = {
            type: 'FeatureCollection',
            features: []
        };

        let countriesLength = 0;
        let officesLength = 0;
        for (const office of offices) {
            // create a HTML element for each feature
            const el = document.createElement('div');
            // el.className = 'marker-dcs-small office ' + office.country.toLowerCase();
            // el.dataset.office = office.id;

            // make a marker for each feature and add to the map
            // new mapboxgl.Marker(el)
            //     .setLngLat(office.latLng.coordinates)
            //     .addTo(map);

            geojsonOffice.features.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: office.latLng.coordinates,
                },
                properties: {
                    office_id: office.id,
                    office_icon: office.country + '_pin',
                },
            });

            if (!countries[office.country]) {
                countries[office.country] = {
                    lng: 0,
                    lat: 0,
                    offices: 0,
                    center: [office.lng, office.lat],
                }
                countriesLength++;
            }
            countries[office.country].lng += office.latLng.coordinates[0];
            countries[office.country].lat += office.latLng.coordinates[1];
            countries[office.country].offices++;
            officesLength++;
        }

        const geojsonGlobe = {
            type: 'FeatureCollection',
            features: []
        };

        let counter = 0;
        jQuery.each(countries, function (country, obj) {
            map.loadImage('/markers/pills/' + country.toLowerCase() + '.png', function (error, image) {
                if (error) {
                    throw error;
                }

                map.addImage(country + '_pill', image);

                geojsonGlobe.features.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [obj.lng / obj.offices, obj.lat / obj.offices],
                    },
                    properties: {
                        office_count: obj.offices.toString(),
                        office_icon: country + '_pill',
                        office_country: country,
                    },
                });

                counter++;
            });

            map.loadImage('/markers/pins/' + country.toLowerCase() + '.png', function (error, image) {
                if (error) {
                    throw error;
                }

                map.addImage(country + '_pin', image);

                counter++;
            });
        });

        const countriesInterval = setInterval(function () {
            if (counter >= countriesLength) {
                clearInterval(countriesInterval);

                console.log('geojsonGlobe', geojsonGlobe)

                // Adding and showing globe markers.
                map.addSource('marker-dcs-pill-globe', {
                    type: 'geojson',
                    data: geojsonGlobe,
                });

                map.addLayer({
                    'id': 'marker-dcs-pill-globe-layer',
                    'type': 'symbol',
                    'source': 'marker-dcs-pill-globe',
                    'layout': {
                        "icon-image": ['get', 'office_icon'],
                        "icon-allow-overlap": true,
                        'icon-size': 0.33,
                        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                        "text-size": 14,
                        "text-transform": "uppercase",
                        "text-letter-spacing": 0.05,
                        "text-offset": [1, 0],
                        'text-field': [
                            'format',
                            ['upcase', ['get', 'office_count']],
                            {'font-scale': 0.8},
                        ],
                    },
                    "paint": {
                        "text-color": "#fff"
                    },
                    'filter': ['==', '$type', 'Point']
                });

                map.on('click', 'marker-dcs-pill-globe-layer', (e) => {
                    let value = e.features[0].properties.office_country;

                    $('#map-offices > div').hide();
                    const markers = [];
                    const llb = new mapboxgl.LngLatBounds();
                    for (const office of offices) {
                        // create a HTML element for each feature
                        if (office['country'] == value) {
                            markers.push(office.latLng.coordinates);
                            llb.extend(office.latLng.coordinates);
                        }
                    }

                    if ($('#map-offices > div[data-show="country"][data-value="' + value + '"]').length > 0) {
                        $('#map-offices > div[data-show="country"][data-value="' + value + '"]').show();
                        $('.network-map-overlay.office').show();
                    } else {
                        $('.network-map-overlay.office').hide();
                    }

                    mode = 'country';
                    onFly = true;
                    if (markers.length > 0) {
                        if (markers.length > 1) {
                            map.fitBounds(llb, {
                                padding: {top: 20, bottom: 20, left: 30, right: 30}
                            }, {
                                mode: 'fitBounds'
                            });
                        } else {
                            map.flyTo({
                                center: markers[0],
                                zoom: 10,
                            }, {
                                mode: 'flyTo'
                            })
                        }
                    }
                });
            }
        }, 100);

        const officesInterval = setInterval(function () {
            if (officesLength >= offices.length) {
                clearInterval(officesInterval);

                // Adding and showing globe markers.
                map.addSource('marker-dcs-pin-globe', {
                    type: 'geojson',
                    data: geojsonOffice,
                });

                map.addLayer({
                    'id': 'marker-dcs-pin-globe-layer',
                    'type': 'symbol',
                    'source': 'marker-dcs-pin-globe',
                    'layout': {
                        "icon-image": ['get', 'office_icon'],
                        "icon-allow-overlap": true,
                        'icon-size': 0.33,
                    },
                    'filter': ['==', '$type', 'Point']
                });
            }

            map.on('click', 'marker-dcs-pin-globe-layer', (e) => {
                let value = e.features[0].properties.office_id;

                $('#map-offices > div').hide();
                const markers = [];
                const llb = new mapboxgl.LngLatBounds();
                for (const office of offices) {
                    // create a HTML element for each feature
                    if (office['id'] == value) {
                        markers.push(office.latLng.coordinates);
                        llb.extend(office.latLng.coordinates);
                    }
                }

                if ($('#map-offices > div[data-office-id="' + value + '"]').length > 0) {
                    $('#map-offices > div[data-office-id="' + value + '"]').show();
                    $('.network-map-overlay.office').show();
                } else {
                    $('.network-map-overlay.office').hide();
                }

                mode = 'country';
            });

            map.setLayoutProperty('marker-dcs-pin-globe-layer', 'visibility', 'none');

        }, 100);

        $('*[data-filter]').click(function (e) {
            let $filter = $(this);
            let filter = $filter.data('filter');
            let value = $filter.data('value');
            let bound = $filter.data('bound');
            scrollTo = $filter.data('scroll');

            $('#map-offices > div').hide();
            const markers = [];
            const llb = new mapboxgl.LngLatBounds();
            for (const office of offices) {
                // create a HTML element for each feature
                if (office[filter] == value) {
                    markers.push(office.latLng.coordinates);
                    llb.extend(office.latLng.coordinates);
                }
            }

            if ($('#map-offices > div[data-show="' + filter + '"][data-value="' + value + '"]').length > 0) {
                $('#map-offices > div[data-show="' + filter + '"][data-value="' + value + '"]').show();
                $('.network-map-overlay.office').show();
            } else {
                $('.network-map-overlay.office').hide();
            }

            if (filter == 'continent') {
                const $tab = $('#' + value + '-tab');
                $tab.tab('show');
                const $accordionCollapse = $('#' + value + '-collapse');
                $accordionCollapse.collapse('show');
            }

            mode = filter;
            onFly = true;
            if (markers.length > 0) {
                if (markers.length > 1) {
                    let center = [
                        (llb._ne.lng + llb._sw.lng) / 2,
                        (llb._ne.lat + llb._sw.lat) / 2,
                    ];
                    map.fitBounds(llb, {
                        padding: {top: 20, bottom: 20, left: 30, right: 30}
                    }, {
                        mode: 'fitBounds'
                    });
                } else {
                    map.flyTo({
                        center: markers[0],
                        zoom: 10,
                    }, {
                        mode: 'flyTo'
                    })
                }
            } else {
                map.fitBounds(bound, {
                    padding: {top: 20, bottom: 20, left: 30, right: 30}
                }, {
                    mode: 'fitBounds'
                });
            }
        });

        $('#map-offices').on('click', '.btn-office', function () {
            let officeId = $(this).data('office-id');
            let link = $(this).data('href');

            for (const office of offices) {
                // create a HTML element for each feature
                if (office.id == officeId) {
                    map.flyTo({
                        center: office.latLng.coordinates,
                        zoom: 18,
                        bearing: 30,
                        pitch: 75
                    }, {
                        mode: 'flyTo'
                    }).on('moveend', function () {
                        window.location = link;
                    })
                }
            }
        });

        let k = 0;

        function startAnimation(type, excludeId) {
            const n = k++;
            let lineDistance = 0;
            if (excludeId === undefined) {
                excludeId = 0;
            }

            let routeStart = null;
            do {
                routeStart = routes[Math.floor(Math.random() * routes.length)];
            } while (routeStart.run || routeStart.type !== type || routeStart.id === excludeId);
            routeStart.run = true;

            for (const returnRoute of routes) {
                if (routeStart.returnRoute === returnRoute.id) {
                    returnRoute.run = true;
                }
            }

            const route_id = routeStart.id;
            const icon = routeStart.type;
            const geojson = routeStart.geojson;
            const origin = [routeStart.origin.lon, routeStart.origin.lat];

            // A single point that animates along the route.
            // Coordinates are initially set to origin.
            const point = {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'Point',
                            'coordinates': origin
                        }
                    }
                ]
            };

            // console.log(n + ") FROM " + routeStart.originName + " TO " + routeStart.destinationName + " VIA " + icon);

            if (routeStart.api) {
                lineDistance = turf.length(geojson.features[0]);
                routing(geojson, icon);
            } else {
                const destinationA = [routeStart.destination.lon, routeStart.destination.lat];

                // A simple line from origin to destination.
                const routeA = {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'LineString',
                                'coordinates': [origin, destinationA]
                            }
                        }
                    ]
                };
                let destinationB = [destinationA[0] + 360, destinationA[1]];
                const routeB = {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'LineString',
                                'coordinates': [origin, destinationB]
                            }
                        }
                    ]
                };
                let destinationC = [destinationA[0] - 360, destinationA[1]];
                const routeC = {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'LineString',
                                'coordinates': [origin, destinationC]
                            }
                        }
                    ]
                };

                const deltaLng = {
                    A: Math.abs(origin[0] - destinationA[0]),
                    B: Math.abs(origin[0] - destinationB[0]),
                    C: Math.abs(origin[0] - destinationC[0]),
                };

                let route = deltaLng.A < deltaLng.B && deltaLng.A < deltaLng.C ? routeA : (
                    deltaLng.B < deltaLng.C ? routeB : routeC
                );

                // Calculate the distance in kilometers between route start/end point.
                lineDistance = turf.length(route.features[0]);

                if (icon === 'ship') {
                    // Effettua una richiesta alla API di SeaRoutes

                    fetch(`https://api.searoutes.com/route/v2/sea/${origin[0]}%2C${origin[1]}%3B${destinationA[0]}%2C${destinationA[1]}`, searouteOptions)
                        .then(res => res.json())
                        .then(function (res) {
                            // Usa i dati della rotta per visualizzarla sulla mappa
                            if (res.features) {
                                lineDistance = turf.length(res.features[0]);
                                addRoute(route_id, res);
                                routing(res, icon);
                            } else {
                                console.error(res);
                                lineDistance = turf.length(geojson.features[0]);
                                routing(geojson, icon);
                            }
                        })
                        .catch(function (err) {
                            console.error(err)
                            lineDistance = turf.length(geojson.features[0]);
                            routing(geojson, icon);
                        });
                } else if (icon === 'truck') {
                    let response = orsClient.calculate({
                        coordinates: [origin, destinationA],
                        profile: 'driving-hgv',
                        avoidables: ['fords'],
                        format: 'geojson'
                    }).then(function (res) {
                        lineDistance = turf.length(res.features[0]);
                        addRoute(route_id, res);
                        routing(res, icon);
                    }).catch(function (err) {
                        console.error(err)
                        lineDistance = turf.length(geojson.features[0]);
                        routing(geojson, icon);
                    })
                } else {
                    addRoute(route_id, route);
                    // console.log("Route type (" + deltaLng.A < deltaLng.B && deltaLng.A < deltaLng.C ? "A" : (
                    //     deltaLng.B < deltaLng.C ? "B" : "C"
                    // ) + ")")
                    routing(route, icon);
                }
            }

            function routing(route, icon) {
                // Number of steps to use in the arc and animation, more steps means
                // a smoother arc and animation, but too many steps will result in a
                // low frame rate

                // let steps = Math.floor(lineDistance / (icon === 'ship' ? 1.5 : (icon === 'truck' ? 100 : 12)));
                let steps = Math.floor(lineDistance / (icon === 'truck' ? 200 : 2));

                const arc = [];

                // Draw an arc between the `origin` & `destination` of the two points
                for (let i = 0; i < lineDistance; i += lineDistance / steps) {
                    const segment = turf.along(route.features[0], i);
                    arc.push(segment.geometry.coordinates);
                }

                // Update the route with calculated arc coordinates
                route.features[0].geometry.coordinates = arc;

                if (icon === 'truck') {
                    const arc2 = [];
                    steps = steps * 100;
                    for (let i = 0; i < lineDistance; i += lineDistance / steps) {
                        const segment = turf.along(route.features[0], i);
                        arc2.push(segment.geometry.coordinates);
                    }

                    route.features[0].geometry.coordinates = arc2;
                }

                // Used to increment the value of the point measurement against the route.
                let counter = 0;

                //// map.on('load', () => {
                // Add a source and layer displaying a point which will be animated in a circle.
                map.addSource('route_' + n, {
                    'type': 'geojson',
                    'data': route
                });

                map.addSource('point_' + n, {
                    'type': 'geojson',
                    'data': point
                });

                map.addLayer({
                    'id': 'route_' + n,
                    'source': 'route_' + n,
                    'type': 'line',
                    'paint': {
                        'line-width': 1,
                        'line-color': '#007cbf',
                        'line-dasharray': [2, 2],
                    }
                });

                map.addLayer({
                    'id': 'point_' + n,
                    'source': 'point_' + n,
                    'type': 'symbol',
                    'layout': {
                        // This icon is a part of the Mapbox Streets style.
                        // To view all images available in a Mapbox style, open
                        // the style in Mapbox Studio and click the "Images" tab.
                        // To add a new image to the style at runtime see
                        // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
                        'icon-image': icon,
                        'icon-size': 0.66,
                        'icon-rotate': ['get', 'bearing'],
                        'icon-rotation-alignment': 'map',
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true
                    }
                });


                let running = false;

                function animate() {
                    running = true;
                    const start =
                        route.features[0].geometry.coordinates[
                            counter >= steps ? counter - 1 : counter
                            ];
                    const end =
                        route.features[0].geometry.coordinates[
                            counter >= steps ? counter : counter + 1
                            ];
                    if (!start || !end) {
                        running = false;

                        // console.log(n + ') Rimuovi A ');
                        startAnimation(routeStart.type, routeStart.returnRoute);
                        map.removeLayer('route_' + n);
                        map.removeLayer('point_' + n);
                        routeStart.run = false;
                        for (const returnRoute of routes) {
                            if (routeStart.returnRoute === returnRoute.id) {
                                returnRoute.run = false;
                            }
                        }
                        return;
                    }
                    // Update point geometry to a new position based on counter denoting
                    // the index to access the arc
                    point.features[0].geometry.coordinates =
                        route.features[0].geometry.coordinates[counter];

                    // Calculate the bearing to ensure the icon is rotated to match the route arc
                    // The bearing is calculated between the current point and the next point, except
                    // at the end of the arc, which uses the previous point and the current point
                    point.features[0].properties.bearing = turf.bearing(
                        turf.point(start),
                        turf.point(end)
                    );

                    // Update the source with this new data
                    map.getSource('point_' + n).setData(point);

                    // Request the next frame of animation as long as the end has not been reached
                    if (counter < steps) {
                        requestAnimationFrame(animate);
                    } else {
                        // console.log(n + ') Rimuovi B');
                        startAnimation(routeStart.type, routeStart.returnRoute);
                        map.removeLayer('route_' + n);
                        map.removeLayer('point_' + n);
                        routeStart.run = false;
                        for (const returnRoute of routes) {
                            if (routeStart.returnRoute === returnRoute.id) {
                                returnRoute.run = false;
                            }
                        }
                        return;
                    }

                    counter = counter + 1;
                }

                // Start the animation
                animate(counter);
            }
        }

        function addRoute(route_id, geojson) {
            // routes[route_id].geojson = geojson;
            // routes[route_id].api = true;
            geojson.features[0].properties = null;
            $.post(Routing.generate('office_add_route'), {
                'route_id': route_id,
                'geojson': geojson,
            }, function (json) {
            });
        }

        // At low zooms, complete a revolution every two minutes.
        const secondsPerRevolution = 90;
        // Above zoom level 5, do not rotate.
        const maxSpinZoom = 5;
        // Rotate at intermediate speeds between zoom levels 3 and 5.
        const slowSpinZoom = 2;

        let userInteracting = false;
        let spinEnabled = true;
        let mode = 'free';
        let onFly = false;

        let sign = -1;

        function spinGlobe() {
            const zoom = map.getZoom();

            if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
                let distancePerSecond = 360 / secondsPerRevolution;
                // let latPerSecond = sign * 360 / (secondsPerRevolution * 5);
                const center = map.getCenter();
                switch (mode) {
                    case 'continent':
                        distancePerSecond = distancePerSecond / 10;
                        // latPerSecond = 0;
                        if (zoom > slowSpinZoom * 1.5) {
                            // Slow spinning at higher zooms
                            const zoomDif = (10 - zoom) / (10 - slowSpinZoom * 2);
                            distancePerSecond *= zoomDif;
                        }
                        break;
                    case 'country':
                        distancePerSecond = distancePerSecond / 30;
                        // latPerSecond = 0;
                        if (zoom > slowSpinZoom * 1.75) {
                            // Slow spinning at higher zooms
                            const zoomDif = (10 - zoom) / (10 - slowSpinZoom * 2.5);
                            distancePerSecond *= zoomDif;
                        }
                        break;
                    default:
                        if (zoom > slowSpinZoom) {
                            // Slow spinning at higher zooms
                            const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
                            distancePerSecond *= zoomDif;
                        }
                        break;
                }
                center.lng -= distancePerSecond;
                // center.lat += latPerSecond;
                // if (center.lat >= 45) {
                //     sign = -1;
                // }
                // if (center.lat <= -15) {
                //     sign = 1;
                // }
                // Smoothly animate the map over one second.
                // When this animation is complete, it calls a 'moveend' event.
                map.easeTo({center, duration: 1000, easing: (n) => n}, {
                    mode: 'spinGlobe',
                });
            }
        }

        // Pause spinning on interaction
        map.on('mousedown', () => {
            userInteracting = true;
        });

        // Restart spinning the globe when interaction is complete
        map.on('mouseup', () => {
            userInteracting = false;
            spinGlobe();
        });

        // These events account for cases where the mouse has moved
        // off the map, so 'mouseup' will not be fired.
        map.on('dragend', () => {
            userInteracting = false;
            mode = 'free';
            spinGlobe();
        });
        map.on('zoomend', (e) => {
            const zoom = map.getZoom();
            if (zoom < 3) {
                map.setLayoutProperty('marker-dcs-pill-globe-layer', 'visibility', 'visible');
                map.setLayoutProperty('marker-dcs-pin-globe-layer', 'visibility', 'none');

            } else {
                map.setLayoutProperty('marker-dcs-pill-globe-layer', 'visibility', 'none');
                map.setLayoutProperty('marker-dcs-pin-globe-layer', 'visibility', 'visible');
            }
            if (!e.mode) {
                mode = 'free';
            }
            onFly = false;
            spinGlobe();
        });

        // When animation is complete, start spinning if there is no ongoing interaction
        map.on('moveend', (e) => {
            if (e.mode && e.mode === 'spinGlobe') {
                spinGlobe();
            } else {
                if (scrollTo) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $("#" + scrollTo).offset().top - 150,
                    }, 0, 'swing', function () {
                        scrollTo = null;
                        scrollTime = 0;
                        spinGlobe();
                    });
                }

            }
        });

        spinGlobe();


        /**
         * Guessing the continent to display based on the country code.
         *
         * AF: Africa
         * AN: Antartide
         * AS: Asia
         * EU: Europa
         * NA: Nord America
         * OC: Oceania
         * SA: Sud America
         */
        let startingContinent = 'EU';
        const continentByCountryCode = {
            AF: 'AS',
            AL: 'EU',
            AQ: 'AN',
            DZ: 'AF',
            AS: 'OC',
            AD: 'EU',
            AO: 'AF',
            AG: 'NA',
            AZ: 'AS',
            AR: 'SA',
            AU: 'OC',
            AT: 'EU',
            BS: 'NA',
            BH: 'AS',
            BD: 'AS',
            AM: 'AS',
            BB: 'NA',
            BE: 'EU',
            BM: 'NA',
            BT: 'AS',
            BO: 'SA',
            BA: 'EU',
            BW: 'AF',
            BV: 'AN',
            BR: 'SA',
            BZ: 'NA',
            IO: 'AF',
            SB: 'OC',
            VG: 'NA',
            BN: 'AS',
            BG: 'EU',
            MM: 'AS',
            BI: 'AF',
            BY: 'EU',
            KH: 'AS',
            CM: 'AF',
            CA: 'NA',
            CV: 'AF',
            KY: 'NA',
            CF: 'AF',
            LK: 'AS',
            TD: 'AF',
            CL: 'SA',
            CN: 'AS',
            TW: 'AS',
            CX: 'AS',
            CC: 'AS',
            CO: 'SA',
            KM: 'AF',
            YT: 'AF',
            CG: 'AF',
            CD: 'AF',
            CK: 'OC',
            CR: 'NA',
            HR: 'EU',
            CU: 'NA',
            CY: 'EU',
            CZ: 'EU',
            BJ: 'AF',
            DK: 'EU',
            DM: 'NA',
            DO: 'NA',
            EC: 'SA',
            SV: 'NA',
            GQ: 'AF',
            ET: 'AF',
            ER: 'AF',
            EE: 'EU',
            FO: 'EU',
            FK: 'SA',
            GS: 'AN',
            FJ: 'OC',
            FI: 'EU',
            AX: 'EU',
            FR: 'EU',
            GF: 'SA',
            PF: 'OC',
            TF: 'AF',
            DJ: 'AF',
            GA: 'AF',
            GE: 'AS',
            GM: 'AF',
            DE: 'EU',
            GH: 'AF',
            GI: 'EU',
            KI: 'OC',
            GR: 'EU',
            GL: 'NA',
            GD: 'NA',
            GP: 'NA',
            GU: 'OC',
            GT: 'NA',
            GN: 'AF',
            GY: 'SA',
            HT: 'NA',
            HM: 'AN',
            VA: 'EU',
            HN: 'NA',
            HK: 'AS',
            HU: 'EU',
            IS: 'EU',
            IN: 'AS',
            ID: 'AS',
            IR: 'AS',
            IQ: 'AS',
            IE: 'EU',
            IL: 'AS',
            IT: 'EU',
            CI: 'AF',
            JM: 'NA',
            JP: 'AS',
            KZ: 'AS',
            JO: 'AS',
            KE: 'AF',
            KP: 'AS',
            KR: 'AS',
            XK: 'EU',
            KW: 'AS',
            KG: 'AS',
            LA: 'AS',
            LB: 'AS',
            LS: 'AF',
            LV: 'EU',
            LR: 'AF',
            LY: 'AF',
            LI: 'EU',
            LT: 'EU',
            LU: 'EU',
            MO: 'AS',
            MG: 'AF',
            MW: 'AF',
            MY: 'AS',
            MV: 'AS',
            ML: 'AF',
            MT: 'EU',
            MQ: 'NA',
            MR: 'AF',
            MU: 'AF',
            MX: 'NA',
            MC: 'EU',
            MN: 'AS',
            MD: 'EU',
            ME: 'EU',
            MS: 'NA',
            MA: 'AF',
            MZ: 'AF',
            OM: 'AS',
            NA: 'AF',
            NR: 'OC',
            NP: 'AS',
            NL: 'EU',
            CW: 'NA',
            AW: 'NA',
            SX: 'NA',
            BQ: 'NA',
            NC: 'OC',
            VU: 'OC',
            NZ: 'OC',
            NI: 'NA',
            NE: 'AF',
            NG: 'AF',
            NU: 'OC',
            NF: 'OC',
            NO: 'EU',
            MP: 'OC',
            UM: 'NA',
            FM: 'OC',
            MH: 'OC',
            PW: 'OC',
            PK: 'AS',
            PS: 'AS',
            PA: 'NA',
            PG: 'OC',
            PY: 'SA',
            PE: 'SA',
            PH: 'AS',
            PN: 'OC',
            PL: 'EU',
            PT: 'EU',
            GW: 'AF',
            TL: 'AS',
            PR: 'NA',
            QA: 'AS',
            RE: 'AF',
            RO: 'EU',
            RU: 'EU',
            RW: 'AF',
            BL: 'NA',
            SH: 'AF',
            KN: 'NA',
            AI: 'NA',
            LC: 'NA',
            MF: 'NA',
            PM: 'NA',
            VC: 'NA',
            SM: 'EU',
            ST: 'AF',
            SA: 'AS',
            SN: 'AF',
            RS: 'EU',
            SC: 'AF',
            SL: 'AF',
            SG: 'AS',
            SK: 'EU',
            VN: 'AS',
            SI: 'EU',
            SO: 'AF',
            ZA: 'AF',
            ZW: 'AF',
            ES: 'EU',
            SS: 'AF',
            SD: 'AF',
            EH: 'AF',
            SR: 'SA',
            SJ: 'EU',
            SZ: 'AF',
            SE: 'EU',
            CH: 'EU',
            SY: 'AS',
            TJ: 'AS',
            TH: 'AS',
            TG: 'AF',
            TK: 'OC',
            TO: 'OC',
            TT: 'NA',
            AE: 'AS',
            TN: 'AF',
            TR: 'EU',
            TM: 'AS',
            TC: 'NA',
            TV: 'OC',
            UG: 'AF',
            UA: 'EU',
            MK: 'EU',
            EG: 'AF',
            GB: 'EU',
            GG: 'EU',
            JE: 'EU',
            IM: 'EU',
            TZ: 'AF',
            US: 'NA',
            VI: 'NA',
            BF: 'AF',
            UY: 'SA',
            UZ: 'AS',
            VE: 'SA',
            WF: 'OC',
            WS: 'OC',
            YE: 'AS',
            ZM: 'AF',
            XD: 'AS',
            XS: 'AS',
            XX: 'OC',
        };

        // Getting the locales and looking for the region.
        console.log(location.hash);

        if (location.hash) {
            startingContinent = location.hash.replace('#', '');
        } else {
            if (navigator.languages) {
                let languageCode = null;
                for (let j = 0; j < navigator.languages.length; j++) {
                    if (navigator.languages[j].length > 2) {
                        languageCode = navigator.languages[j];
                        break;
                    }
                }

                if (languageCode !== null) {
                    const region = languageCode.split('-')[1];

                    if (region && continentByCountryCode[region.toUpperCase()]) {
                        startingContinent = continentByCountryCode[region.toUpperCase()];
                    }
                }
            }

            startingContinent = 'EU';
        }

        // Selecting the first tab/accordion.
        const $tab = $('#' + startingContinent + '-tab');
        $tab.tab('show');
        const $accordionCollapse = $('#' + startingContinent + '-collapse');
        $accordionCollapse.collapse('show');
    });
});
